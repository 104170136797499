import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  ECaseConfirmDialogComponent,
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal, LovDataService,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../formbuilderForms/form/dialog.service';
import cloneDeep from "lodash/cloneDeep";
import {PageEvent} from "@angular/material/paginator";


/*
  Properties of confChange
  hideYearsExpenditure - Hides Year rows in main table and in the summary table
  showSubCategoryInSummary - Shows Sub-categories in summary table
  subBlocks.hideCellInDetailView - Hides component in the main table
  showCategoriesTotal - Shows sub-total for categories in the main table
  showSubCategoriesTotal - Shows sub-total for sub-categories in the main table
  numberOfYears - Years quantity
 */
@Component({
  selector: 'fb-budget-table',
  templateUrl: './fb-budget-table.component.html',
  styleUrls: ['./fb-budget-table.component.scss']
})
export class FbBudgetTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() showSaveNavBar: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Input() teamTableIndex: number;
  @Input() sections;
  @Input() teamTableBlockName: string;
  @Input() teamTableNestedTableBlockName: string;
  originalValueForShowSaveNavBar = true;
  showDetails = false;
  showAddEditTableForm: boolean = false;
  selectedTableRowIndex = -1;
  selectedTable: any;
  copyBlockData: any;
  indexOfEditedRow;
  subscription: Subscription;
  formValidationSubscription: Subscription;
  globalComponent;
  widthDividerForDynamicWidth = 1;
  tableConfChange;
  isDragActive: boolean;
  rowIndexBeingDragged: number;
  beforeFilteringRows = [];
  showFilteredImage = true;
  pageSizeOptions = [5, 10, 25, 100];
  pageSize = 10;
  pageIndex = 0;
  hideEditButton: boolean;
  tempRow: any;
  listOfGreyColumns = [];
  columnAsRowColspan = 4 ; // Default value. Adjust based on the number of columns in the table.

  constructor(private translate: TranslateService, private matDialog: MatDialog, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe, private eCaseHttpService: EcaseHttpService,
              private refreshDataService: RefreshDataService, private dialogService: DialogService, private eCaseSnackBarService: ECaseSnackBarService,
              private pendingChangesGuard: PendingChangesGuard, private lovDataService: LovDataService) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.tableConfChange = this.confChange;
    this.hideEditButton = false;
  }


  openDialog(confChange) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: confChange.dialogText, selectedLanguage: this.selectedLanguage}
    });
    console.log('donee');
  }

  onDrop(event): void {
    if (this.confChange.isTablePaginated) {
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      console.log(previousIndex);
      console.log(currentIndex);
      if (this.confChange.onRowDragEndFunction) {
        this.confChange.onRowDragEndFunction(this.globalPrj, this.globalConf, previousIndex, currentIndex, this.selectedLanguage);
      }
      if (this.pageIndex === 0) {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = index > (this.pageSize  - 1);
          return row;
        });
      } else {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = (index < ((this.pageIndex * this.pageSize) - 1)) || (index > ((this.pageIndex * this.pageSize) + this.pageSize - 1));
          return row;
        });
      }
      if (Math.abs(currentIndex - previousIndex) === 1) {
        [this.bphChange.rows[currentIndex], this.bphChange.rows[previousIndex]] = [this.bphChange.rows[previousIndex], this.bphChange.rows[currentIndex]];
      } else {
        [this.bphChange.rows[currentIndex], this.bphChange.rows[previousIndex]] = [this.bphChange.rows[previousIndex], this.bphChange.rows[currentIndex]];
        // previousIndex >= currentIndex ? this.bphChange.rows.splice(currentIndex, 0, this.bphChange.rows.splice(previousIndex, 1)[0]) : this.bphChange.rows.splice(currentIndex - 1, 0, this.bphChange.rows.splice(previousIndex, 1)[0]);
      }
    } else {
      if (this.confChange.onRowDragEndFunction) {
        this.confChange.onRowDragEndFunction(this.globalPrj, this.globalConf, event.previousIndex, event.currentIndex, this.selectedLanguage);
      }
      if (Math.abs(event.currentIndex - event.previousIndex) === 1) {
        [this.bphChange.rows[event.currentIndex], this.bphChange.rows[event.previousIndex]] = [this.bphChange.rows[event.previousIndex], this.bphChange.rows[event.currentIndex]];
      } else {
        event.previousIndex >= event.currentIndex ? this.bphChange.rows.splice(event.currentIndex, 0, this.bphChange.rows.splice(event.previousIndex, 1)[0]) : this.bphChange.rows.splice(event.currentIndex - 1, 0, this.bphChange.rows.splice(event.previousIndex, 1)[0]);
      }
    }

    this.pendingChangesGuard.isPristine = false;
    this.isDragActive = false;
  }

  updateDragStatus(event, rowIndex): void {
    this.rowIndexBeingDragged = rowIndex;
    this.isDragActive = true;
    const documentInputField = document.getElementsByClassName('cdk-drag-preview')[0];
    if (documentInputField) {
      const curStyle = documentInputField.getAttribute('class');
      if (!curStyle.includes(' inDrag')) {
        documentInputField.setAttribute('class', ((curStyle ? curStyle : '') + ' inDrag'));
      }
    }
  }

  resetFilters(): void {
    console.log("got inside reset filter")
    this.bphChange.rows = cloneDeep(this.beforeFilteringRows);
    this.pageIndex = 0;
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize  - 1);
        return row;
      });
    }
    this.showFilteredImage = true;
    this.confChange.subBlocks.forEach(subBlock => {
      subBlock.valueToFilter = '';
    });
  }

  isFilterFieldVisible(subBlock): boolean {
    return subBlock.type === 'text' || subBlock.type === 'statictext' || subBlock.type === 'select' || subBlock.type === 'AutoComplete';
  }

  applyFilter(event, subBlock): void {
    if (this.confChange.isColumnsFiltered) {
      if (!event.target.value || event.target.value === '') {
        this.resetFilters();
      } else {
        this.pageIndex = 0;
        //this.beforeFilteringRows = this.bphChange.rows;
        this.bphChange.rows = this.bphChange.rows.filter(row => {
          if (subBlock.type === 'text' || subBlock.type === 'statictext') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = ''
            }
            return row[subBlock.name].value.toString().toLowerCase().includes(event.target.value.toLowerCase());
          } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = {};
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            if (!row[subBlock.name].value.label) {
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            return row[subBlock.name].value.label[this.selectedLanguage].toLowerCase().includes(event.target.value.toLowerCase());
          } else {
            return true;
          }
        });
        if (this.confChange.isTablePaginated) {
          this.bphChange.rows = this.bphChange.rows.map((row, index) => {
            row.isHidden = index > (this.pageSize  - 1);
            return row;
          });
        }
        this.showFilteredImage = false;
      }
    }
  }

  updatePagination(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex === 0) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (pageEvent.pageSize - 1);
        return row;
      });
    } else {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = (index <= ((pageEvent.pageIndex * pageEvent.pageSize) - 1)) || (index > ((pageEvent.pageIndex * pageEvent.pageSize) + pageEvent.pageSize - 1));
        return row;
      });
    }
  }

  sortRows(subBlock: any): void {
    if (subBlock.isSortable) {
      if (subBlock.sortDirection === 'asc') {
        subBlock.sortDirection = 'desc';
      } else if (subBlock.sortDirection === 'desc') {
        subBlock.sortDirection = 'asc';
      } else {
        subBlock.sortDirection = 'asc';
      }
      this.confChange.subBlocks.filter(e => e.name !== subBlock.name).forEach(block => {
        block.sortActive = false;
      })
      const direction = subBlock.sortDirection === 'asc' ? 1 : -1
      this.bphChange.rows = this.bphChange.rows.sort((a, b) => {
        if (subBlock.type === 'text' || subBlock.type === 'statictext') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = ''
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = ''
          }
          return (direction * a[subBlock.name].value.toString().toLowerCase().localeCompare(b[subBlock.name].value.toString().toLowerCase()));
        } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = {};
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!a[subBlock.name].value.label) {
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = {};
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value.label) {
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          return (direction * a[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase().localeCompare(b[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase()));
        } else {
          return 0
        }
      });
      this.pageIndex = 0;
      if (this.confChange.isTablePaginated) {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = index > (this.pageSize  - 1);
          return row;
        });
      }
      subBlock.sortActive = true;
    }
  }

  generateBudgetTables(): void {
    if(!this.bphChange.budgetTables) {
      this.bphChange.budgetTables = []
    }

    if (this.confChange.budgetPerTeam) {
      console.log(`//---------// BUDGET PER TEAM TRUE //-----------//\nPRJ -> ${this.globalPrj}`);
      const thisRef = this;
      if (this.confChange.tableSection && this.confChange.tableComponent && this.confChange.tableSubComponent && this.confChange.budgetPerTeamType == 'table') {
        this.globalPrj[this.confChange.tableSection][this.confChange.tableComponent].rows.forEach((row, index) => {
          let teamName = row[thisRef.confChange.tableSubComponent].value;
          console.log(`//--//  TEAM NAME = ${teamName}\ncaseApplicationTeamId = ${row.caseApplicationTeamId}\nuniqueRowIndex = ${row.uniqueRowIndex}`);
          if (typeof teamName !== 'string') {
            teamName = teamName.label;
          } else {
            teamName = {
              'fr': teamName,
              'en': teamName,
              'ar': teamName
            };
          }
          const uniqueTable = thisRef.bphChange.budgetTables.filter(table => table.id === row.uniqueRowIndex)[0];
          if (uniqueTable) {
            // Rename table if label has changed
            uniqueTable.label = teamName;
            uniqueTable.caseApplicationTeamId = row.caseApplicationTeamId;
          } else {
            // add table if it does not exist
            let hasOptionSelected = false;
            if (index === 0) {
              hasOptionSelected = true;
            }
            thisRef.bphChange.budgetTables.push(thisRef.getTableConfig(true, teamName, hasOptionSelected, row.uniqueRowIndex, row.caseApplicationTeamId));
            this.bphChange.budgetTables[0].teamFunded = '1';
          }
        });
      } else if (this.confChange.budgetPerTeamType == 'list') {
        this.globalPrj[this.selectedSectionId].budgetTableList.forEach((row, index) => {
          let teamName = row.teamName;
          const uniqueTable = thisRef.bphChange.budgetTables.filter(table => table.id === row.uniqueRowIndex)[0];
          if (uniqueTable) {
            // Rename table if label has changed
            uniqueTable.label = teamName;
            uniqueTable.caseApplicationTeamId = row.caseApplicationTeamId;
          } else {
            // add table if it does not exist
            let hasOptionSelected = false;
            if (index === 0) {
              hasOptionSelected = true;
            }
            thisRef.bphChange.budgetTables.push(thisRef.getTableConfig(true, teamName, hasOptionSelected, row.uniqueRowIndex, row.caseApplicationTeamId));
            this.bphChange.budgetTables[0].teamFunded = '1';
          }
        })
      }
    } else {
      if (this.bphChange.budgetTables.length === 0) {
        this.bphChange.budgetTables.push(this.getTableConfig());
      }
    }

    this.bphChange.budgetTables[0].teamFunded = '1';
  }

  getTableConfig(showLabel = false, label: any = '', hasOptionSelected = true, id: any = false, caseApplicationTeamId: any = false) {
    if (typeof label === 'string') {
      label = {
        'fr': label,
        'en': label,
        'ar': label
      };
    }
    const table = {
      'showLabel': showLabel,
      'label': label,
      'hasOptionSelected': hasOptionSelected,
      'id': id,
      'caseApplicationTeamId': caseApplicationTeamId,
      'rows': [],
      'renderRows': []
    };
    return table;
  }

  ngOnInit() {
    console.log(`--- INITIALISE BUDGET TABLE ---\n//---// BPH CHANGE //---//\n${this.bphChange}`);
    this.originalValueForShowSaveNavBar = cloneDeep(this.showSaveNavBar);
    this.tableConfChange = this.confChange;
    this.widthDividerForDynamicWidth = this.confChange.subBlocks.length;
    if (this.confChange.paginationValues) {
      this.pageSizeOptions = this.confChange.paginationValues.split(',').map(e => Number(e));
      this.pageSize = this.pageSizeOptions[0];
    }

    // generate tables for each team if enabled in admin page
    this.generateBudgetTables();

    this.refreshDataService.getAllExpenditureItems(this.globalPrj.competitionIds[0]).subscribe((item) => {

      item = item.expenditureItems.filter(exp => {
        // Check for typeOfItems filter
        const typeOfItemsFilter = !this.confChange.typeOfItems || exp.budgetClassificationId === this.confChange.typeOfItems.id;
        // Check for budgetChartId filter
        const budgetChartIdFilter = !this.confChange.budgetChartId || exp.chartId === Number.parseInt(this.confChange.budgetChartId);
        // Return true only if both conditions are met
        return typeOfItemsFilter && budgetChartIdFilter;
      });

      this.globalPrj.categoryList = item.map((expenditure) => {
        expenditure.value = expenditure.childExpenditureId;
        expenditure.label = expenditure.childExpenditure;
        expenditure.children = expenditure.children.map((child) => {
          child.value = child.childExpenditureId;
          child.label = child.childExpenditure;
          return child;
        }).sort((a, b) => a.sortingKey > b.sortingKey);
        return expenditure;
      }).sort((a, b) => a.sortingKey > b.sortingKey);
    })

    if (this.confChange.readOnlyTable) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (this.confChange.showDelete) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (!this.confChange.tableLabel) {
      this.confChange.tableLabel = {};
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
      this.bphChange.renderRows = [];
    }

    if (!this.confChange.yearLabel) {
      this.confChange.yearLabel = { 'en': 'Year', 'fr': 'Année' };
    }

    if (!this.confChange.subtotalLabel) {
      this.confChange.subtotalLabel = {'en': 'Total', 'fr': 'Total'}
    }

    if (!this.confChange.summaryTabelLabel) {
      this.confChange.summaryTabelLabel = {'en': 'Summary table', 'fr': 'Sommaire'}
    }

    if (!this.confChange.numberOfYears || this.confChange.numberOfYears === 1) {
      this.confChange.numberOfYears = 1;
      this.confChange.hideYearsExpenditure = true;
    }

    // get a list with places for grey columns and calculated totals for one row
    this.getListOfGreyColumns();

    if (!this.bphChange.yearRows || this.bphChange.yearRows.length !== this.confChange.numberOfYears) {
      this.bphChange.yearRows = [];
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        this.bphChange.yearRows.push({
          'value': 0,
          'error': {}
        });
      }
    }

    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }

    this.generateAndSortRowsToRender();
    this.generateSummaryTable();

  }

  getTranslatedLabel(key: string) {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  getListOfGreyColumns() {
    let labelsColumn = false;
    // first index is for expenditure icon (background grey)
    if (!this.confChange.hideYearsExpenditure) {
      this.listOfGreyColumns.push({name: false});
    }
    for (let i = 0; i < this.confChange.subBlocks.length; i++) {
      const subBlock = this.confChange.subBlocks[i];
      if (subBlock.hasBudgetSubTotal || subBlock.name === 'total') {
        if (!labelsColumn) {
          labelsColumn = true;
          this.listOfGreyColumns[this.listOfGreyColumns.length - 1]['label'] = true;
        }
        this.listOfGreyColumns.push({name: this.confChange.subBlocks[i].name});
      } else if (!subBlock.hideCell) {
        this.listOfGreyColumns.push({name: false});
      }
    }
    // last two indexes are for edit and delete columns (background grey)
    this.listOfGreyColumns.push({name: false}, {name: false});
  }

  getRange(value: number): number[] {
    return Array.from({ length: value }, (_, index) => index);
  }

  ngAfterViewInit() {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': false,
        'isValidTable': !subBlock.readOnlyTable,
        'isValidTableReadonly': subBlock.readOnlyTable
      };
      subBlock.templateContext2 = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false
      };
      return subBlock;
    });
  }

  executeFunction(confChange, bphChange, rowIndex) {
    if (confChange.updateOutputEventFunctions) {
      this.globalConf.http = this.eCaseHttpService;
      this.globalConf.teamTableIndex = this.teamTableIndex;
      for (const functionName of confChange.updateOutputEventFunctions) {
        confChange[functionName](bphChange.value, rowIndex, this.globalPrj, this.globalConf, this.selectedLanguage, this.refreshDataService);
      }
    }
  }


  doNothing() {
  }

  getAlignClass(): string {
    switch (this.confChange.totalTextAlign) {
      case  'start':
        return 'align-start';
      case 'end' :
        return 'align-end';
      case undefined :
        return '';
    }
  }

  addTableRow(blockData, blockConf, table, tableIndex) {
    console.log(`--- ADD ROW TO TABLE --- \nbphChange - ${blockData}\nconfChange - ${blockConf}\ntable - ${table}`);
    blockConf.isAddRowFunctionActive = true;
    blockConf.isEditRowFunctionActive = false;

    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
      ECaseUtils.scrollToTop('.content-wrapper');
    }

    table.rows = table.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = table.rows.length > 0 ? (Math.max.apply(Math, table.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(ECaseUtils.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });

    // adding expand rows with quantity of years set in the admin
    aRow['expandRows'] = []
    if (this.confChange.numberOfYears) {
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        const yearLabelObject = cloneDeep(this.confChange.yearLabel);
        Object.keys(this.confChange.yearLabel).forEach((key) => {
          yearLabelObject[key] = this.confChange.yearLabel[key] + ' ' + (i + 1);
        });
        let expandRow: any = {
          'year': i + 1,
          'label': yearLabelObject,
          'subTotal': {
            'value': '',
            'error': {}
          }
        };
        aRow.expandRows.push(expandRow);
      }
    }

    this.tempRow = aRow;
    blockConf.total.hideCellInDetailView = !blockConf.hideYearsExpenditure;
    blockData.budgetTables[tableIndex].rows.push(aRow)
    blockData.budgetTables[tableIndex]['selectedRowIndex'] = blockData.budgetTables[tableIndex].rows - 1;
    this.selectedTable = table;
    this.selectedTableRowIndex = table.rows.length - 1;
    if (blockConf.readOnlyTable) {
      blockData.selectedRow = aRow;
      this.showAddEditTableForm = true;
      if (blockConf.name != 'sub_table') {
        for (let k = 0; k < globalArray.length; k++) {
          try {
            ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
            ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
            ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
          } catch (e) {
          }
        }
        if (this.confChange.selectedContainerName) {
          this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
            if (row.sub_table) {
              row.sub_table.showDetails = false;
            }
          });
        }
        blockData.showDetails = true;
      }
      blockConf.subBlocks.forEach(function(entity) {
        aRow[entity.name].globalShowDetail = true;
      });
      window.scrollTo(0, 0);
    }
    if (this.confChange.addRowFunction) {
      this.confChange.addRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage, tableIndex);
    }
    this.bphChange = blockData;
    this.confChange = blockConf;
    this.pendingChangesGuard.isPristine = false;
    this.showAddEditTableForm = true;
    this.indexOfEditedRow = undefined;
  }

  getTotal(rows, column, j, tcolumn, row, currency, totalText, type, confChange) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
        row[column]['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
    }
  }

  mathFloor(number) {
    return Math.floor(number);
  }

  parseInt(number) {
    return parseInt(number, 10);
  }

  multiYearRowIndexCalculator(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return rowIndex % (noOfYears + 1);
  }

  getHeaderRowIndex(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return (noOfYears + 1) * (Math.floor(rowIndex / (noOfYears + 1)));
  }

  closeDetails(blockConf, blockData) {

    console.log('//---// SAVE BUTTON BUDGET TABLE ROW DETAIL PRESSED //---//');
    console.log(blockConf);
    console.log(blockData);

    this.globalConf.showDetails = false;

    if (this.confChange.hideYearsExpenditure) {
      blockData.budgetTables.forEach(table => {
        table.rows.forEach(row => {
          row.expandRows[0].subTotal.value = Number.parseInt(row.total.value) || 0;
          row.total.value = Number.parseInt(row.total.value) || 0;
        })
      })
    }

    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }

    if (blockConf.isAddRowFunctionActive && blockConf.addRow) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage, blockData);
    }
    if (blockConf.isEditRowFunctionActive) {
      if (typeof blockConf.editRow === 'function') {
        console.log('//---// EDIT ROW FUNCTION IS ACTIVE //---//');
        blockConf.editRow(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    if (blockConf.isAfterSaveFunctionActive) {
      if (typeof blockConf.afterSaveFunction === 'function') {
        blockConf.afterSaveFunction(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }

    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()

    this.refreshDataService.toggleShowSaveNavBar(true);
    this.showAddEditTableForm = false;
  }

  getTotalDynamic(rows, column, j, dynaIndex, row, currency, totalText) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        let tcolumn;
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'][dynaIndex], 10))) {
              newCellVal = Math.round(parseFloat(rows[i][column]['value'][dynaIndex]) * 100) / 100;
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return currencySign;
    }
  }

  editRow(blockData, blockConf, index, tableIndex) {
    console.log('//---// EDIT ROW //---//');
    blockConf.isAddRowFunctionActive = false;
    blockConf.isEditRowFunctionActive = true;
    this.selectedTableRowIndex = index;
    this.selectedTable = blockData.budgetTables[tableIndex];
    this.showAddEditTableForm = true;
    this.copyBlockData = _.cloneDeep(this.bphChange);
    if (blockConf.type === 'SchedulerTable') {
      blockData.rowsForSchedulerTable[index].rowIndex = index;
      blockData.selectedRowIndex = index;
    }
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }

    this.tempRow = blockData.budgetTables[tableIndex].rows[index];
    blockData.selectedRow = blockData.budgetTables[tableIndex].rows[index];
    blockConf.showDetails = true;
    blockData.showDetails = true;
    this.globalConf.showDetails = true;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    if (blockConf.name !== 'sub_table' || blockConf.isolateSectionOnEdit) {
      for (let k = 0; k < globalArray.length; k++) {
        try {
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
          ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        } catch (e) {
        }
      }
      if (this.confChange.selectedContainerName && this.confChange.selectedContainerName.split('.')[0]) {
        this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
          if (row.sub_table) {
            row.sub_table.showDetails = false;
          }
        });
        this.bphChange.showDetails = true;
      }
    }

    blockData['selectedRowIndex'] = index;
    if (this.confChange.editRowFunction) {
      this.confChange.editRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()
    // this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  orderTableBycolumn(blockData, blockConf, column) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      blockData.rows = _.orderBy(blockData.rows, column);
    }
  }

  orderTable(blockData, blockConf) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      const groupByColumns = blockConf.orderedBy.split(',');
      if (blockConf.orderedByTypes) {
        const groupByTypes = blockConf.orderedByTypes.split(',');
        blockData.rows = _.orderBy(blockData.rows, groupByColumns, groupByTypes);
      } else {
        blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      }
    }
  }

  createArrayOfLength(n): any[] {
    return Array.from(Array(n).keys());
  }

  filterHideCellSubBlocks(subBlocks: any[]): any[] {
    return subBlocks.filter(e => !e.hideCell);
  }

  generateAndSortRowsToRender() {
    this.bphChange.budgetTables.forEach(table => {
      if(table.rows?.length) {
        this.sortRowsToRender(table);
      } else {
        table.renderRows = [];
      }
    })
  }

  // Function to sort rows by Categories and Sub categories
  sortRowsToRender(table) {
    // Filtering rows to get only unique rows which has information to sort
    let rows = table.rows.filter(item => item.uniqueRowIndex)
    // Sorting the array by select_category.value.value and select_sub_category.value.value
    rows.sort((a, b) => {
      const categoryComparison = a.select_category.value.value - b.select_category.value.value;
      if (categoryComparison !== 0) {
        return categoryComparison;
      }

      return a.select_sub_category.value.value - b.select_sub_category.value.value;
    });
    table.rows = rows;
    const result = [];
    let currentCategory = null;
    let currentSubCategory = null;
    let currCatSum = 0;
    let currSubCatSum = 0;
    let grandTotalSum = 0;
    let tmpSubCatObj;
    let tmpCatObj;

    let subTotals = [];

    this.confChange.subBlocks.forEach(obj => {
      if (obj.hasBudgetSubTotal) {
        subTotals.push({
          name: obj.name,
          currency: obj.currency,
          currCatSum: 0,
          currSubCatSum: 0,
          grandTotalSum: 0
        })
      }
    })

    for (const [index, item] of rows.entries()) {
      if (currentSubCategory !== item.select_sub_category.value.value) {
        // Add a new object for the sub-category sum (skip for the first iteration)
        if (currentSubCategory !== null) {
          tmpSubCatObj = {
            isHidden: !this.confChange.showSubCategoriesTotal,
            isSubCategoryTotal: true,
            subCategoryId: currentSubCategory,
            label: {
              'en': 'Sub Category sub-total',
              'fr': 'Sous-catégorie Sous-total'
            },
            value: {
              error: {},
              value: currSubCatSum
            }
          }
          subTotals.forEach(sub => {
            tmpSubCatObj[sub.name] = {
              value: sub.currSubCatSum,
              currency: sub.currency
            }
            sub.currSubCatSum = Number.parseInt(item[sub.name].value);
          })
          result.push(tmpSubCatObj);
        }

        subTotals.forEach(sub => {
          sub.currSubCatSum = Number.parseInt(item[sub.name].value);
        })
        // Update current sub-category and reset sum
        currentSubCategory = item.select_sub_category.value.value;
        currSubCatSum = item.total.value || 0;
      } else {
        subTotals.forEach(sub => {
          sub.currSubCatSum += Number.parseInt(item[sub.name].value);
        })
        currSubCatSum += item.total.value || 0;
      }

      if (currentCategory !== item.select_category.value.value) {
        // Add a new object for the category sum (skip for the first iteration)
        if (currentCategory !== null) {
          tmpCatObj = {
            isHidden: !this.confChange.showCategoriesTotal,
            isCategoryTotal: true,
            categoryId: currentCategory,
            label: {
              'en': 'Category sub-total',
              'fr': 'Sous-total de la catégorie'
            },
            value: {
              error: {},
              value: currCatSum
            }
          }
          subTotals.forEach(cat => {
            tmpCatObj[cat.name] = {
              value: cat.currCatSum,
              currency: cat.currency
            }
            cat.grandTotalSum += cat.currCatSum;
            cat.currCatSum = Number.parseInt(item[cat.name].value);
          })
          result.push(tmpCatObj);
          grandTotalSum += currCatSum;
        }
        subTotals.forEach(cat => {
          cat.currCatSum = Number.parseInt(item[cat.name].value);
        })
        // Update current category and reset sum
        currentCategory = item.select_category.value.value;
        currCatSum = item.total.value || 0;
      } else {
        subTotals.forEach(cat => {
          cat.currCatSum += Number.parseInt(item[cat.name].value);
        })
        currCatSum += item.total.value || 0;
      }

      // Add the original item
      item['renderPosition'] = index;
      result.push(item);
    }

    // Add the last sub-category and category sums
    tmpSubCatObj = {
      isHidden: !this.confChange.showSubCategoriesTotal,
      isSubCategoryTotal: true,
      subCategoryId: currentSubCategory,
      label: {
        'en': 'Sub Category sub-total',
        'fr': 'Sous-catégorie Sous-total'
      },
      value: {
        error: {},
        value: currSubCatSum
      }
    }
    subTotals.forEach(sub => {
      tmpSubCatObj[sub.name] = {
        value: sub.currSubCatSum,
        currency: sub.currency
      }
    })
    result.push(tmpSubCatObj);
    tmpCatObj = {
      isHidden: !this.confChange.showCategoriesTotal,
      isCategoryTotal: true,
      categoryId: currentCategory,
      label: {
        'en': 'Category sub-total',
        'fr': 'Sous-total de la catégorie'
      },
      value: {
        error: {},
        value: currCatSum
      }
    }
    subTotals.forEach(sub => {
      tmpCatObj[sub.name] = {
        value: sub.currCatSum,
        currency: sub.currency
      }
    })
    result.push(tmpCatObj);
    let grandTotalObj = {
      isTotal: true,
      label: {
        'en': 'Total',
        'fr': 'Total'
      },
      value: {
        error: {},
        value: grandTotalSum + currCatSum
      }
    }
    subTotals.forEach(sub => {
      grandTotalObj[sub.name] = {
        value: sub.grandTotalSum + sub.currCatSum,
        currency: sub.currency
      }
    })
    result.push(grandTotalObj)
    table.renderRows = result;
  }

  reshuffleTable(blockData, blockConf) {
    if (blockConf && blockConf.showGroupBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      if (blockData.rows.length === 0) {
        return;
      }
      const groupByColumns = blockConf.groupedBy.split(',');
      blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      for (let i = groupByColumns.length - 1; i >= 0; i--) {
        if (i - 1 >= 0) {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, groupByColumns[i - 1]);
        } else {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, undefined);
        }
      }
    }
  }

  getValueForFbSelectSubBlock(currentValue, lang) {
    if (currentValue.value && currentValue.value.value >= ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      return currentValue.otherValue;
    } else {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    }
  }

  getOtherValueForFbSelectSubBlock(currentValue) {
    return currentValue.otherValue;
  }

  reshuffleColumn(blockData, blockConf, gbcolumn, k, gPreviouscolumn) {
    let oldCol = 0;
    const indexes = [];
    const groupColumn = gbcolumn.split('.')[0];
    if (gPreviouscolumn) {
      eval('oldCol = blockData.rows[0].' + gbcolumn + ' +\'_\' +blockData.rows[0].' + gPreviouscolumn);
    } else {
      eval('oldCol = blockData.rows[0].' + gbcolumn);
    }
    /* Ignore tslint error: "ESLint: 'rowSpanRow' is never reassigned, use 'const' instead."
    It is being reassigned on line #552. Changing to const will cause errors in the form */
    let rowSpanRow: any = {};
    eval('rowSpanRow = blockData.rows[0].' + groupColumn);
    rowSpanRow.rowspan = 0;
    let newCol;
    for (let i = 0; i < blockData.rows.length; i++) {
      if (!blockData.rows[i].isTotal) {
        if (gPreviouscolumn) {
          eval('newCol = blockData.rows[i].' + gbcolumn + ' +\'_\' +blockData.rows[i].' + gPreviouscolumn);
        } else {
          eval('newCol = blockData.rows[i].' + gbcolumn);
        }
        console.log('groupColumn');
        console.log(groupColumn);
        if (oldCol !== newCol) {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=false');
          indexes.push(i);
          oldCol = newCol;
          eval('rowSpanRow = blockData.rows[i].' + groupColumn);
          rowSpanRow.rowspan = 1;
        } else {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=true');
          rowSpanRow.rowspan++;
        }
      } else {
        rowSpanRow.rowspan++;
      }
    }
    eval('blockData.rows[0].' + groupColumn + '.hideCell=false');
    indexes.push(blockData.rows.length + 1);
    let moved = 0;
    for (let i = 0; i < blockConf.subBlocks.length; i++) {
      if (blockConf.subBlocks[i].name === groupColumn && !blockConf.subBlocks[i].noSubTotal) {
        for (let i = 0; i < indexes.length; i++) {
          blockData.rows.splice(indexes[i] + moved, 0, {'isTotal': true, 'position': k, 'column': groupColumn});
          moved++;
        }
      }
    }
  }
  handleEnterKey(event , confChange){
    if (!confChange.readOnlyTable) {
      event.preventDefault();
    }
  }

  deleteRow(blockData, blockConf, index, tableIndex) {
    console.log(`//---// DELETE ROW ${index} IN TABLE ${tableIndex} //---//`);
    if (blockConf.isConfirmDelete) {
      if (typeof blockConf.confirmDelete === 'function') {
        if (blockConf.subBlocks) {
          for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
            if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
              const subBlockName = blockConf.subBlocks[ii].name;
              if (blockData.budgetTables[tableIndex].rows[index][subBlockName].value) {
                for (let iiTobd = 0; iiTobd < blockData.budgetTables[tableIndex].rows[index][subBlockName].value.length; iiTobd++) {
                  if (blockData.budgetTables[tableIndex].rows[index][subBlockName].value[iiTobd] && blockData.budgetTables[tableIndex].rows[index][subBlockName].value[iiTobd].fileUploaded) {
                    if (!this.globalPrj.upload_to_be_deleted) {
                      this.globalPrj.upload_to_be_deleted = [];
                    }
                    this.globalPrj.upload_to_be_deleted.push(blockData.budgetTables[tableIndex].rows[index][subBlockName].value[iiTobd].id);
                  }
                }
              }
            }
          }
        }
        blockConf.confirmDelete(this.globalPrj, this.globalConf, this.matDialog, this.translate, index, this, this.dialogService);
      } else {
        if (this.confChange.isConfirmDeleteText) {
          const confirmDialogRef = this.matDialog.open(ECaseConfirmDialogComponent, {
            width: '700px',
          });
          const instance = confirmDialogRef.componentInstance;
          instance.confirmMessage = this.confChange.isConfirmDeleteText[this.selectedLanguage];
          confirmDialogRef.afterClosed().subscribe((decision) => {
            if(decision){
              const deletedRow = _.cloneDeep(blockData.budgetTables[tableIndex].rows[index]);
              this.deleteTableRow(blockData, blockConf, index, tableIndex);
              if (this.confChange.deleteRowFunction) {
                this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, index, this, this.selectedLanguage, deletedRow);
              }
            }
          });
        }
      }
    } else {
      const deletedRow = _.cloneDeep(blockData.budgetTables[tableIndex].rows[index]);
      this.deleteTableRow(blockData, blockConf, index, tableIndex);
       if (this.confChange.deleteRowFunction) {
        this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, index, this, this.selectedLanguage,deletedRow);
      }
    }

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()
  }

  // Do not delete, used in block js of some tables.
  deleteMultiYearRow(i, blockData, blockConf) {
    blockData.noOfYears = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.noOfYears, 10)) ? 1 : parseInt(blockData.noOfYears, 10);
    const rowModulesIndex = (i % (noOfYears + 1)).toString();
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName]?.value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][rowModulesIndex + ii.toString()][subBlockName].value.length; iiTobd++) {
              if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd] && ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }

    blockData.rows.splice(i, noOfYears + 1);

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()
  }

  deleteTableRow(blockData, blockConf, i, tableIndex) {
    console.log('//---// DELETE TABLE ROW CALLED //---//');
    this.pendingChangesGuard.isPristine = false;
    this.globalPrj.isConfirmationSaved = false;

    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.budgetTables[tableIndex].rows[i][subBlockName].value) {
            for (let iiTobd = 0; iiTobd < blockData.budgetTables[tableIndex].rows[i][subBlockName].value.length; iiTobd++) {
              if (blockData.budgetTables[tableIndex].rows[i][subBlockName].value[iiTobd] && blockData.budgetTables[tableIndex].rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.budgetTables[tableIndex].rows[i][subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }
    blockData.budgetTables[tableIndex].rows.splice(i, 1);

    this.bphChange = _.cloneDeep(blockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;

    if (blockConf.isDeleteRowFunctionActive) {
      if (typeof blockConf.afterDeleteRowFunction === 'function') {
        blockConf.afterDeleteRowFunction(this.globalPrj, this.globalConf, this, this.bphChange);
      };
    };

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()

  }

  getGlobalTotal(rows, column, j, row, currency, totalText, confChange) {
    if (totalText && !confChange.isCountRows) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (!rows[i].isTotal && !rows[i].skipTotal) {
            let newCellVal = 0;
            if (rows[i][column] && confChange.type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && confChange.type === 'select') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.value ? 1 : 0;
            } else if (rows[i][column] && confChange.type === 'text' && confChange.isCountRows) {
              const arr = rows[i][column]['value'];
              newCellVal = arr ? 1 : 0;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
        }
        // r = Math.round(r * 10) / 10;
        const newTotalRow = {};
        newTotalRow['isTotal'] = true;
        newTotalRow['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '0,0';
      }
      let total = '';
      if (confChange.inputSuffix) {
        total = (confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed)) + ' ' + confChange.inputSuffix;
      } else {
        total = confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
      }
      return total;
    }
  }

  calculateMultiplication(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null) {
        res = n1 * n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  calculateDivision(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null && n2 !== 0) {
        res = n1 / n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  getExpressionOutputNumber(row, label, lang, i) {
    let r;
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    if (isNaN(r)) {
      r = '';
    }
    return r;
  }

  getExpressionOutput(row, label, lang, i) {
    const r = '';
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    return r;
  };

  cancel(blockConf, blockData) {
    console.log('//---// CANCEL IN ROW DETAIL PRESSED //---//');
    console.log(blockConf);
    console.log(blockData);
    blockConf.showDetails = false;
    blockData.showDetails = false;
    this.globalConf.showDetails = false;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (this.confChange.selectedContainerName) {
      console.log(this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows);
      this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
        if (row.sub_table) {
          row.sub_table.showDetails = false;
        }
      });
    }
    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }
    console.log(this.copyBlockData);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    this.bphChange.showDetails = false;
    if (this.teamTableBlockName && this.teamTableNestedTableBlockName) {
      this.globalPrj[this.selectedSectionId][this.teamTableBlockName].rows[this.teamTableIndex][this.teamTableNestedTableBlockName] = this.bphChange;
    } else {
      (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
    }
    this.refreshDataService.tableCancelClicked(this.globalPrj);
    setTimeout(() => {
      const element = document.getElementById(this.selectedSectionId + '_' + this.confChange.name);
      element.scrollIntoView();
    }, 100);

    this.generateAndSortRowsToRender()
    this.generateSummaryTable()

    this.showAddEditTableForm = false;
  }

  isDialogOptionsEnabledForList(option): boolean {
    return this.confChange.showDialogForOptions && this.confChange.optionsDialogList.filter(item => item.id === option).length > 0;
  }

  openDialogForOptions(option): void {
    const dialogObj = this.confChange.optionsDialogList.filter(item => item.id = option)[0];
    this.openDialog(dialogObj);
  }

  ngOnDestroy(): void {
    this.confChange.showDetails = false;
    this.bphChange.showDetails = false;
    this.showDetails = false;
    this.globalConf.showDetails = false;

    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.formValidationSubscription)
      this.formValidationSubscription.unsubscribe();
  }

  showTableRow(row, index) {
    console.log(index);
    row.isShowTableRow = !row.isShowTableRow;
  }

  getTextLength2(l): number {
    try {
      l = l.toString();
      if (l.length > 9) {
        l = l.slice(0, 9);
      }
      return l.length;
    } catch (e) {
      return 0;
    }
  }

  onSubTotalInput2($event?) {
    if ($event) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
      }
    }
  }

  onOtherInstitutionFundsInput(table, row) {
    let total = 0;
    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.fundsFromOtherInstitution.value);
      }
    }
    if (!row.grandTotalForOtherFunds) {
      row.grandTotalForOtherFunds = {};
    }
    row.grandTotalForOtherFunds.value = total;
    this.pendingChangesGuard.isPristine = false;
  }

  onSubTotalInput(table, row, expandRow?, $event?) {

    if ($event && expandRow.subTotal !== null) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        if (!expandRow.subTotal) {
          expandRow.subTotal = {};
        }
        expandRow.subTotal.value = expandRow.subTotal.value ? expandRow.subTotal.value : 0;
        expandRow.subTotal.value = +((expandRow.subTotal.value).toString().slice(0, t.getAttribute('maxlength')));
        console.log('SUBTOTAL', expandRow.subTotal);
      }
    }

    let total = 0;
    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.subTotal.value);
      }
    }

    if (!row.total.value) {
      row.total.value = 0;
    }
    row.total.value = total;
    this.pendingChangesGuard.isPristine = false;
  }

  convertNumberToCurrencyFormat(value, currency) {
    return this.confChange.isFormattingDisabled ? value : this.eCaseNumberFormatterPipe.transform(value, currency, this.selectedLanguage, false, currency);
  }

  filterInvalidCategories(categories: any): any {
    const validKeys = Object.keys(categories).filter(key => !key.includes('undefined'));
    const obj = cloneDeep(categories);
    Object.keys(obj).forEach((key) => {
      if (!validKeys.includes(key)) {
        delete categories[key];
      }
    });
    return categories;
  }

  sortArray(arr: any[]): any[] {
    return arr ? arr.sort((rowa, rowb) => {
      if (rowa.value.sortingKey < rowb.value.sortingKey) {
        return -1;
      } else if (rowa.value.sortingKey > rowb.value.sortingKey) {
        return 1;
      }
      return 0;
    }) : [];
  }

  generateSummaryTable() {
    console.log('//---// GENERATE SUMMARY BUDGET TABLE //---//');

    let sumTable = {
      yearTotals: cloneDeep(this.bphChange.yearRows),
      grandSubTotal: { 'value': 0, 'error': {} },
      rowsMap: {}
    };

    let rowSearchKey = this.confChange.showSubCategoryInSummary ? 'select_sub_category' : 'select_category';
    let sortingKey = 0;

    this.bphChange.budgetTables.forEach(table => {
      table.renderRows.forEach((row) => {
        if (row.uniqueRowIndex && row.select_category.value?.label && row.select_sub_category.value?.label) {
          if (!sumTable.rowsMap[row[rowSearchKey].value.value]) {
            sortingKey += 1;
            sumTable.rowsMap[row[rowSearchKey].value.value] = {
              sortingKey: sortingKey,
              rowTotal: { 'value': 0, 'error': {} },
              categoryLabel: row.select_category.value.label,
              subCategoryLabel: row.select_sub_category.value.label,
              yearRows: cloneDeep(this.bphChange.yearRows)
            }
          }

          row.expandRows.forEach((year, i) => {
            sumTable.rowsMap[row[rowSearchKey].value.value].yearRows[i].value += Number.parseInt(year.subTotal.value) || 0;
            sumTable.yearTotals[i].value += Number.parseInt(year.subTotal.value) || 0;
          })

          sumTable.rowsMap[row[rowSearchKey].value.value].rowTotal.value += Number.parseInt(row.total.value) || 0;
          sumTable.grandSubTotal.value += Number.parseInt(row.total.value) || 0;
        }
      });
    })

    sumTable['rows'] = Object.values(sumTable.rowsMap);
    this.bphChange.summaryTable = sumTable;
  }

  toggleTable(table) {
    if (table) {
      table.hidden = !table.hidden;
    }
  }

  checkForSelectNo($event, table) {
    console.log(`CHECK TABLE FOR SELECT\n${table.teamFunded}\n${table}`);
    if (table.teamFunded === '2') {
      table.hidden = true;
    } else {
      table.hidden = false;
    }
    this.generateSummaryTable();
  }

  getColumnAsRowSubBlocks() {
    return this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => item.columnAsRow);
  }

  getFilteredSubBlocks() {
    return this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => !item.columnAsRow);
  }

  getColumnAsRowColspan(){
    const showDeleteButtonCount = this.confChange.showDelete ? 1 : 0;
    const showEditButtonCount = !this.confChange.hideEditButton ? 1 : 0;
    const deleteEditRowCount = showDeleteButtonCount + showEditButtonCount;

    const totalSubBlocks = this.confChange.subBlocks.length;
    const columnAsRowCount = this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => item.columnAsRow).length;

    // -1 represents the first column used for displaying the column label.
    this.columnAsRowColspan = totalSubBlocks - columnAsRowCount + deleteEditRowCount - 1;
    return this.columnAsRowColspan ;
  }


}
